import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/ui/Layout';
import '../fragments.ts';
import PageSEO from '../components/PageSEO';

import '../styles/main.scss';
import Hero, { HeroQueryProps } from '../components/ui/Hero';
import { LocalizedSEO } from '../fragments';
import { HeaderProps } from '../components/ui/Header';
import Section, { Module } from '../components/ui/Section';
import { FooterProps } from '../components/Footer';
import { useGlobalState } from '../state/globalStateContext';
import TopBanner from '../components/ui/TopBanner';

import * as styles from './Page.module.scss';
import {
  ALLOWED_BANNERS,
  BANNER_QUERY_PARAM,
  FILE_DOWNLOAD_URLS_BY_PARAM_VALUE,
  FILE_QUERY_PARAM,
} from '../constants';
import { clsx, removeParamsFromQueryParams } from '../utils/utils';

export const query = graphql`
  query GetPageById($id: String!) {
    sanityLpViralPage(id: { eq: $id }) {
      title
      slug {
        current
      }
      hideSignInButton
      isHomepage
      hero {
        ...Hero
      }
      sections {
        title
        useTitleOnlyAsLabel
        description
        content {
          ...Modules
        }
      }
      seo {
        ...SEO
      }
    }
    sanityLpViralSettings {
      header {
        signInButtonText
        anchorButtonText
      }
      footer {
        privacyPolicyPage {
          slug {
            current
          }
        }
      }
    }
  }
`;

export interface QueryData {
  sanityLpViralPage: {
    title: string;
    slug: {
      current: string;
    };
    hideSignInButton?: boolean;
    isHomepage: boolean;
    hero?: HeroQueryProps;
    sections: Array<{
      title: string;
      useTitleOnlyAsLabel: boolean;
      description: string;
      content: Array<Module>;
    }>;
    seo: LocalizedSEO;
  };
  sanityLpViralSettings: {
    header: HeaderProps;
    footer: FooterProps;
  };
}

const Page = ({ data }: { data: QueryData }): React.ReactElement => {
  const { setHomepageSlug, setHideSignInButton } = useGlobalState();
  const { title, slug, hideSignInButton, isHomepage, hero, sections, seo } = data.sanityLpViralPage;
  const { header, footer } = data.sanityLpViralSettings;
  const [heroButtonAnimated, setHeroButtonAnimated] = useState(false);
  const [shouldStopHeroButtonAnimation, setShouldStopHeroButtonAnimation] = useState(false);
  const [secondsToStopHeroButtonAnimated, setSecondsToStopHeroButtonAnimated] = useState(0);
  const [banner, setBanner] = useState<null | typeof ALLOWED_BANNERS[number]>(null);
  const [downloadFile, setDownloadFile] = useState<null | string>(null);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const bannerParamValue = urlSearchParams.get(BANNER_QUERY_PARAM);
    const fileParamValue = urlSearchParams.get(FILE_QUERY_PARAM);

    if (bannerParamValue || fileParamValue) {
      // Remove the banner and file query param
      removeParamsFromQueryParams([BANNER_QUERY_PARAM, FILE_QUERY_PARAM]);
    }

    if (
      bannerParamValue &&
      ALLOWED_BANNERS.includes(bannerParamValue as typeof ALLOWED_BANNERS[number]) &&
      (bannerParamValue !== 'guide-downloading' || fileParamValue)
    ) {
      setBanner(bannerParamValue as typeof ALLOWED_BANNERS[number]);
    }

    if (fileParamValue && FILE_DOWNLOAD_URLS_BY_PARAM_VALUE.hasOwnProperty(fileParamValue)) {
      setDownloadFile(fileParamValue);
      window.open(FILE_DOWNLOAD_URLS_BY_PARAM_VALUE[fileParamValue]);
    }
  }, []);

  function startHeroButtonAnimation() {
    if (!heroButtonAnimated) {
      setHeroButtonAnimated(true);
      setShouldStopHeroButtonAnimation(false);
      setSecondsToStopHeroButtonAnimated(5);
    }
  }

  useEffect(() => {
    if (!shouldStopHeroButtonAnimation && secondsToStopHeroButtonAnimated > 0) {
      setTimeout(() => {
        setSecondsToStopHeroButtonAnimated(secondsToStopHeroButtonAnimated - 1);
        // Use 950 instead of 1000 to take into account the time it takes
        // to rerender between each loop, which seems to be ~50ms
      }, 950);
    } else if (heroButtonAnimated) {
      setHeroButtonAnimated(false);
      setShouldStopHeroButtonAnimation(false);
      setSecondsToStopHeroButtonAnimated(0);
    }
  }, [secondsToStopHeroButtonAnimated]);

  useEffect(() => {
    if (window.sessionStorage.getItem('shouldAnimateHeroButton') === 'true') {
      startHeroButtonAnimation();
      window.sessionStorage.removeItem('shouldAnimateHeroButton');
    }

    // If we are in an homepage, save the slug to local storage
    if (isHomepage) {
      window.localStorage.setItem('entry_homepage_slug', slug.current);
      setHomepageSlug(slug.current);
    }

    if (hideSignInButton) {
      window.localStorage.setItem('hide_sign_in_button', slug.current);
      setHideSignInButton(hideSignInButton);
    }
  }, []);

  return (
    <Layout
      privacyPolicyPage={footer.privacyPolicyPage}
      signInButtonText={header.signInButtonText}
      anchorButtonText={header.anchorButtonText}
      onHeaderAnchorButtonClick={() => startHeroButtonAnimation()}
      className={clsx(styles.layoutPage, banner ? styles.withMarginTop : '')}
    >
      <PageSEO defaultTitle={title} pageSEO={seo} />
      {banner === 'guide-thank-you' && (
        <TopBanner onClose={() => setBanner(null)}>
          Thank you for your interest! Your guide is on the way. <br></br>Please check your email.
        </TopBanner>
      )}

      {banner === 'guide-downloading' && downloadFile && (
        <TopBanner onClose={() => setBanner(null)}>
          Your guide is downloading! <br></br>
          <a href={FILE_DOWNLOAD_URLS_BY_PARAM_VALUE[downloadFile]} download>
            Click here if the download didn't start
          </a>
        </TopBanner>
      )}
      {hero && (
        <Hero
          {...hero}
          startTrialButtonAnimated={heroButtonAnimated}
          onStartTrialButtonMouseEnter={() => {
            setShouldStopHeroButtonAnimation(true);
          }}
        />
      )}
      {sections.map((section, sectionIndex) => (
        <Section
          key={sectionIndex}
          sectionIndex={sectionIndex}
          lastSection={sectionIndex === sections.length - 1}
          hasHero={!!hero}
          {...section}
        ></Section>
      ))}
    </Layout>
  );
};

export default Page;
