import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';

import '../../fragments.ts';
import { replaceNewLinesWithBr } from '../../utils/sanity';

import { handleGoToPlanSubmit } from '../../api';
import { clsx, urlJoin, wrapCurlyBracedInSpan, wrapSquareBracketedWithEm } from '../../utils/utils';
import EmailInput from './EmailInput';
import * as styles from './Hero.module.scss';

export const HeroFragment = graphql`
  fragment Hero on SanityLpViralHero {
    videoUrl
    title
    text
    buttonText
    buttonSubtitle
    plansPage {
      slug {
        current
      }
    }
    onSubmitKeapTagIds
  }
`;

export type HeroQueryProps = {
  videoUrl: string;
  title: string;
  text: string;
  buttonText: string;
  buttonSubtitle: string;
  plansPage: {
    slug: {
      current: string;
    };
  };
  onSubmitKeapTagIds: Array<string>;
};

export type HeroProps = HeroQueryProps & {
  startTrialButtonAnimated?: boolean;
  onStartTrialButtonMouseEnter?: () => void;
};

const Hero = ({
  videoUrl,
  title,
  text,
  buttonText,
  buttonSubtitle,
  plansPage,
  onSubmitKeapTagIds,
  startTrialButtonAnimated,
  onStartTrialButtonMouseEnter,
}: HeroProps): React.ReactElement => {
  const [userEmail, setUserEmail] = useState<string | null>(null);

  useEffect(() => {
    const userEmailStored = window.localStorage.getItem('email');
    setUserEmail(userEmailStored || '');
  }, []);

  return (
    <div id="hero" className={clsx(styles.section)}>
      <div className={styles.contentContainer}>
        <div className={styles.textContainer}>
          <h1 className={styles.title}>{replaceNewLinesWithBr(title)}</h1>
          <div className={styles.divider}></div>
          <p className={styles.text}>
            {' '}
            {replaceNewLinesWithBr(text, str =>
              wrapSquareBracketedWithEm(str, wrapCurlyBracedInSpan),
            )}
          </p>
        </div>
        <div className={styles.emailInputContainer}>
          <span className={styles.fakeAnchor} id="hero-anchor"></span>
          {userEmail !== null && (
            <EmailInput
              id="form-hero"
              inputInitialValue={userEmail}
              buttonText={wrapCurlyBracedInSpan(buttonText)}
              buttonSubtitle={buttonSubtitle}
              buttonAnimated={startTrialButtonAnimated}
              onSubmit={({ email }) =>
                handleGoToPlanSubmit(
                  email,
                  'hero',
                  plansPage ? urlJoin(plansPage.slug.current) : undefined,
                  onSubmitKeapTagIds,
                )
              }
              onButtonMouseEnter={onStartTrialButtonMouseEnter}
            />
          )}
        </div>
        <div className={styles.videoContainer}>
          <iframe
            src={videoUrl + '?autoPlay=false&playerColor=f43232'}
            title="Hook Point Viral"
            allow="fullscreen"
            allowFullScreen
            frameBorder="0"
            scrolling="no"
            className={styles.video}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Hero;
